import logo from './logo.svg';
import './App.css';
import Home from './Components/Home/Home';
import SplashScreen from './Components/Includes/SplashScreen/SplashScreen';

function App() {
  return (
    <div className="App">
      <SplashScreen/>
      <Home/>
    </div>
  );
}

export default App;
