import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners'; // Importing spinner
import './SplashScreen.css'; // Custom CSS for splash screen styling

const SplashScreen = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading process
    const timer = setTimeout(() => {
      setLoading(false); // Hide splash screen after 3 seconds
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  if (!loading) return null; // Remove the splash screen when loading is complete

  return (
    <div className="splash-screen">
      <div className="splash-content">
        {/* Replace the text with your logo */}
        <h1 className="logo">Test Logo</h1> 
        <ClipLoader size={50} color="#ffffff" /> {/* Spinner Loader */}
      </div>
    </div>
  );
};

export default SplashScreen;
