import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import whiteCurved from '../../Images/white-curved.jpg'
import trevelImg1 from '../../Images/trevel-img1.png'

const CustomCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        <div>
        <div className="row mt-4">
            <div className="col-lg-12 mb-lg-0 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="d-flex flex-column h-100">
                        <p className="mb-1 pt-2 text-bold">Explore the World</p>
                        <h5 className="font-weight-bolder">Discover Your Next Destination</h5>
                        <p className="mb-5">
                          From breathtaking landscapes, vibrant cultures, and unforgettable experiences, find everything you need for your perfect trip.
                        </p>
                        <a
                          className="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                          href="javascript:;"
                        >
                          Read More
                          <i
                            className="fas fa-arrow-right text-sm ms-1"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                      <div className="bg-gradient-primary border-radius-lg h-100">
                          <img
                          src={whiteCurved}
                          className="position-absolute h-100 w-50 top-0 d-lg-block d-none"
                          alt="whiteCurved"
                        />
                        <div className="position-relative d-flex align-items-center justify-content-center h-100">
                        <img
                            className="w-100 position-relative z-index-2 pt-4"
                            src={trevelImg1}
                            alt="trevel1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Second Slide */}
        <div>
        <div className="row mt-4">
            <div className="col-lg-12 mb-lg-0 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="d-flex flex-column h-100">
                        <p className="mb-1 pt-2 text-bold">Explore the World</p>
                        <h5 className="font-weight-bolder">Discover Your Next Destination</h5>
                        <p className="mb-5">
                          From breathtaking landscapes, vibrant cultures, and unforgettable experiences, find everything you need for your perfect trip.
                        </p>
                        <a
                          className="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                          href="javascript:;"
                        >
                          Read More
                          <i
                            className="fas fa-arrow-right text-sm ms-1"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                      <div className="bg-gradient-primary border-radius-lg h-100">
                          <img
                          src={whiteCurved}
                          className="position-absolute h-100 w-50 top-0 d-lg-block d-none"
                          alt="whiteCurved"
                        />
                        <div className="position-relative d-flex align-items-center justify-content-center h-100">
                        <img
                            className="w-100 position-relative z-index-2 pt-4"
                            src={trevelImg1}
                            alt="trevel1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </Slider>
    </div>
  );
};

export default CustomCarousel;
